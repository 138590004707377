// @TODO: Remove amplify as a dependency
import "@aws-amplify/ui-react/styles.css";
import * as prismic from "@prismicio/client";
import * as React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createHome } from "./pages/home/create";
import { createPinsHome } from "./pages/pins/home/create";
import { createPinsPin } from "./pages/pins/pin/create";
import { createScanPin } from "./pages/pins/scan/create";
import { AnonymousAuthClient } from "./services/auth/anonymous-auth-client";
import { PrismicContentClient } from "./services/content/prismic-content-client";
import { installNativeService } from "./services/native";
import { Router } from "./ui/router/router";
import { Store } from "./ui/store/store";

ReactGA.initialize("G-4MHLK4NRZK");

const authController = new AnonymousAuthClient(window.localStorage);

const _prismicContentClient = prismic.createClient("zoopinz", {
  accessToken:
    "MC5ZdVZDOGhBQUFDa0FwNEg0.77-977-9Gu-_vUXvv73vv71n77-9LH7vv71277-9ce-_ve-_vRLvv73vv71277-9O--_ve-_ve-_ve-_vW3vv73vv73vv70S",
});

// TODO: Comment this out for prod
// const localDevUrlDecorator = (url: string) => url.replace("https://zoopinz.com", "");
// const contentController = new PrismicContentClient(_prismicContentClient, localDevUrlDecorator);
const prodNoopUrlDecorator = (url: string) => url;
const contentController = new PrismicContentClient(_prismicContentClient, prodNoopUrlDecorator);

const navigate = (to: string) => browserRouter.navigate(to);
const brandStore = new Store({
  selectedBrandId: undefined as string | undefined,
});
brandStore.subscribe(v => console.log(v));

const rootRouter = new Router();
const pinsRouter = new Router();

rootRouter.get("/", async () => {
  const [brand, slices] = await Promise.all([
    contentController.getBrand("zoopinz"),
    contentController.getHomePage(),
  ]);
  const bootstrap = {
    brand,
    slices,
  };
  return createHome({ bootstrap });
}).get("/pinz463ddd25-34cc-4728-9286-86cfb6c9a634", () => {
  window.location.pathname = window.location.pathname.replace("/pinz", "/pinz/");
  return () => null;
}).use("/pinz", pinsRouter);

pinsRouter.get("/", async () => {
  const [brand, brands, pins] = await Promise.all([
    contentController.getBrand("zoopinz"),
    contentController.getBrands(),
    contentController.getPins(),
  ]);
  const bootstrap = {
    brand,
    brands,
    pins,
    auth: authController.getAuthState(),
    // enableCarouselView: true,
  };
  return createPinsHome({
    bootstrap,
    navigate,
    brandStore,
  });
}).get("/scan", async () => {
  const bootstrap = {
    brand: await contentController.getBrand("zoopinz"),
  };
  return createScanPin({
    bootstrap,
    navigate,
  });
}).get("/:pinId", async ({ params }) => {
  if (!params.pinId) {
    // TODO: Implement not found
    return () => <div>Pin not found</div>;
  }
  const pin = await contentController.getPin(params.pinId);
  brandStore.update(s => ({ ...s, selectedBrandId: pin.brand.id }));
  const authState = authController.getAuthState();
  await authController.addPinToUser(authState.user, pin.id);
  const bootstrap = {
    pin,
    authState,
  };
  ReactGA.gtag("event", "pin_loaded", {
    name: pin.name,
    user_id: authState.user.id,
  });
  return createPinsPin({ bootstrap, navigate });
});

const browserRouter = createBrowserRouter(rootRouter.routes);

const Root = () => {
  return (
    <React.StrictMode>
      <RouterProvider router={browserRouter}/>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<Root/>);
installNativeService(window, navigate);
