import { Image } from "@aws-amplify/ui-react";
import * as React from "react";
import { Link } from "react-router-dom";
import type { Brand } from "services";
import styled from "styled-components";
import { colorNeutralT1 } from "ui/colors";
import CameraIcon from "ui/icons/CameraIcon.svg";
import zoopinzLogo from "../../assets/zoopinz-logo.png";

type NavigationProps = {
  LeftIcon?: React.ComponentType,
  brand: Brand,
  linkOverride?: string,
};

export const Navigation = React.memo(({
  LeftIcon,
  brand,
  linkOverride,
}: NavigationProps) => (
  <NavBar
    brand={brand}
    style={{
      justifyContent: brand.id === "zoopinz" ? "center" : "space-evenly",
      paddingRight: brand.id === "zoopinz" ? "initial" : "50px",
    }}
  >
    {LeftIcon && (
      <MenuButton placement="left">
        <LeftIcon/>
      </MenuButton>
    )}
    {brand.id !== "zoopinz" && (
      <LinkWrapper>
        <Link to={linkOverride ?? "/"} style={{ display: "flex", alignItems: "center" }}>
          <Logo
            alt={"ZooPinz logo"}
            src={zoopinzLogo}
            style={{
              maxWidth: "160px",
              minHeight: "16px",
              maxHeight: "40px",
              marginRight: "16px",
            }}
          />
        </Link>
      </LinkWrapper>
    )}
    <LinkWrapper style={{ height: "64px", maxWidth: "120px" }}>
      <a href={brand.logo.href} style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <Logo
          alt={brand.logo.image.alt ?? "Brand Logo"}
          src={brand.logo.image.url}
          style={{
            width: "auto",
            height: "90%",
            objectFit: "contain",
          }}
        />
      </a>
    </LinkWrapper>
    <MenuButton placement="right">
      <Link
        to="/pinz/scan"
        style={{ textDecoration: "none", display: "flex", alignItems: "center" }}
      >
        <Logo alt="Scan a new pin" src={CameraIcon} width="24px" marginRight={16}/>
      </Link>
    </MenuButton>
  </NavBar>
));

const NavBar = styled.div<{ brand: Brand }>`
  max-height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ brand }) => brand.colors.primaryBackground};
  color: ${({ theme }) => theme.colorPrimaryForeground};
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: calc(env(safe-area-inset-top) + 16px) calc(env(safe-area-inset-right) + 16px) max(calc(env(safe-area-inset-top) - 32px), 16px) calc(env(safe-area-inset-left) + 16px);
`;

const LinkWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled(Image)`
  filter: brightness(0) invert(1);
`;

const MenuButton = styled.div<{ placement: "left" | "right" }>`
  position: absolute;
  top: max(calc(env(safe-area-inset-top) + 8px), 50%);
  transform: translateY(-50%);
  ${props => props.placement === "left" && "left: 0;"}
  ${props => props.placement === "right" && "right: 0;"}
`;

export const MenuItem = styled.div`
  box-sizing: border-box;
  padding: 24px 32px;
  width: 100%;
  border-bottom: 1px solid ${colorNeutralT1};
  margin: 0 auto;
  :hover {
    background-color: ${({ theme }) => theme.colorSecondaryHover};
  }
`;
