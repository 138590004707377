import { Image } from "@aws-amplify/ui-react";
import * as React from "react";
import { Button } from "ui/button";
import { Navigation as NavigationImpl } from "ui/navigation/navigation";
import type { Store } from "ui/store/store";
import { createObserver } from "ui/store/store";
import { Icon } from "../../../ui/icons/icon";
import type { PinsHomeBootstrap } from "./bootstrap";
import { PinsCatalog } from "./catalog/catalog";
import { PinsHome } from "./home";
import styles from "./picker.module.css";

type PinsHomeFactoryOpts = {
  bootstrap: PinsHomeBootstrap,
  navigate: (to: string) => void,
  brandStore: Store<{ selectedBrandId: string | undefined }>,
};

const UNRELEASED_PINS = new Set([
  "78919b0b-2181-4070-af0d-828600632f59", // Gorilla from Zoos Victoria
]);

export const createPinsHome = ({
  bootstrap,
  navigate,
  brandStore,
}: PinsHomeFactoryOpts) => {
  const { brand, brands, pins, auth: { user } } = bootstrap;
  const userPinIds = new Set(user?.attributes.pin_ids ?? []);

  const ScanButton = () => (
    <Button
      variant="primary"
      brand={brand}
      onClick={() => navigate("/pinz/scan")}
    >
      Click here to scan a pin
    </Button>
  );

  const brandsWithPins = new Set(pins.filter(p => !UNRELEASED_PINS.has(p.id)).map(p => p.brand.id));

  const Picker = createObserver(brandStore)(({ store: { selectedBrandId } }) => (
    <div className={styles.picker}>
      {selectedBrandId == null
        ? (
          <>
            <div className={styles.title}>Select your zoo</div>
            <div className={styles.brands}>
              {brands.filter(b => brandsWithPins.has(b.id)).map(b => (
                <div
                  className={styles.brand}
                  key={b.id}
                  onClick={() => brandStore.update(s => ({ ...s, selectedBrandId: b.id }))}
                >
                  <Image height={100} objectFit="contain" alt={b.name} src={b.logo.image.url}/>
                </div>
              ))}
            </div>
          </>
        )
        : (
          <div
            className={styles.back}
            onClick={() => brandStore.update(s => ({ ...s, selectedBrandId: undefined }))}
          >
            <Icon direction="left" glyph="arrow" color="black"/>
            <div style={{ marginLeft: "10px" }}>Back to Zoos</div>
          </div>
        )}
    </div>
  ));

  const Body = createObserver(brandStore)(({ store }) => (
    <PinsCatalog
      pins={pins.filter(pin =>
        !UNRELEASED_PINS.has(pin.id) && pin.brand.id === store.selectedBrandId
      ).map(pin => ({
        ...pin,
        disabled: !userPinIds.has(pin.id),
      }))}
    />
  ));

  const Navigation = createObserver(brandStore)(({ store }) => (
    <NavigationImpl brand={brands.find(b => b.id === store.selectedBrandId) ?? brand}/>
  ));

  return () => (
    <>
      <Navigation brand={brand}/>
      <PinsHome ScanButton={ScanButton} Picker={Picker} Body={Body}/>
    </>
  );
};
